import LocationModal from '../LocationModal'
import LocationImport from '../LocationImport'
import locationService from '../../../../services/notisphere/location'

export default {
    props: {
        supplierOrgId: { type: Number, default: null }
    },
    components: {
        LocationModal,
        LocationImport
    },
    data() {
        return {
            total: 0,
            items: [],
            search: {
                idn: null,
                state: null,
                city: null,
                verified: null
            },
            searchQuery: {},
            header: [
                {
                    label: 'IDN/Provider Name',
                    name: 'idn',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Location Id',
                    name: 'location_id',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Location Name',
                    name: 'location_name',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Address',
                    name: 'address1',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left'
                },
                {
                    label: 'Notisphere ID',
                    name: 'notisphere_id',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left'
                },
                {
                    label: 'Last Update',
                    name: 'last_change_at',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left'
                },
                {
                    label: 'Status',
                    name: 'status',
                    sort: true,
                    row_text_alignment: 'text-center',
                    column_text_alignment: 'text-center'
                },
                {
                    label: '',
                    name: 'action',
                    row_text_alignment: 'text-center',
                    column_text_alignment: 'text-center'
                }
            ],

            locationModalKey: 0,
            locationImportKey: 0,

            listIDN: [],
            listCity: [],
            listState: [],
            listStatus: [{ text: 'Covered', value: 'Covered' },{ text: 'Invalid', value: 'Invalid' },
                { text: 'Not Covered', value: 'NotCovered' },{ text: 'Not Mapped', value: 'Not Mapped' }, { text: 'Pending', value: 'Pending' }],
        }
    },
    mounted() {
        this.getSearchMeta()
    },
    methods: {
        onCompleted(type) {
            if (type == 'UPDATE') {
                this._showToast('Updated successfully!')
            }
            if (type == 'INSERT') {
                this._showToast('Added successfully!')
            }
            this.fetchData()
        },
        addMoreClick() {
            this.locationModalKey += 1
            this.$nextTick(() => {
                this.$refs.locationModal.forceRerenderInsert()
            })
        },
        updateLocation(payload) {
            this.locationModalKey += 1
            this.$nextTick(() => {
                this.$refs.locationModal.forceRerenderUpdate(payload.location_id)
            })
        },
        fetchDelay() {
            this.$refs.table.fetchDelay()
        },
        fetchData() {
            locationService.sup_findLocation({
                ...this.searchQuery,
                sortField: this.searchQuery.sort[0] ? this.searchQuery.sort[0].name : null,
                sortDirection: this.searchQuery.sort[0] ? this.searchQuery.sort[0].order : null,
                ...this.search
            }, this.supplierOrgId).then(resp => {
                if (!resp.error) {
                    this.items = resp.data.d.c
                    this.total = resp.data.d.t
                }
            })
        },
        deleteLocation(item, e) {
            this._showConfirm('You are about to delete this location, this cannot be undone.').then(confirm => {
                if (confirm) {
                    locationService.sup_deleteLocation(item.location_id, this.supplierOrgId).then(resp => {
                        if (!resp.error) {
                            this.onCompleted('UPDATE')
                        }
                    })
                }
            })
            e.stopPropagation()
        },
        importFile() {
            this.locationImportKey += 1
            this.$nextTick(() => {
                this.$refs.locationImport.forceRerenderInsert()
            })
        },
        getSearchMeta() {
            locationService.sup_getSearchMeta(this.supplierOrgId).then(resp => {
                if (!resp.error) {
                    var data = resp.data.d
                    this.listIDN = [
                        ...data.idn.map(x => { return { text: x, value: x } })
                    ]
                    this.listCity = [
                        ...data.city.map(x => { return { text: x, value: x } })
                    ]
                    this.listState = [
                        ...data.state.map(x => { return { text: x, value: x } })
                    ]
                }
            })
        },
    }
}