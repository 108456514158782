import LocationList from './LocationList'
import orgService from '../../../services/notisphere/organization'

export default {
    components: {
        LocationList
    },
    data() {
        return {
            supplierOrgId: null,
            listSupplier: [],
            locationListKey: 0,
            show: false,
        }
    },
    mounted(){
        this.findSuppliers()
    },
    watch: {
        supplierOrgId() {
            if(this.supplierOrgId === null)
            {
                this.show = false
            }
            else
            {
                this.show = false
                this.locationListKey += 1
                this.$nextTick(()=>{
                    this.show = true
                })
            }
        }
    },
    methods: {
        findSuppliers() {
            orgService.findMeta('SUPPLIER').then(resp => {
                this.listSupplier = resp.data.d
            })
        }
    }
}